import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "canvas-panel", class: { embedded: _vm.embedded } },
    [
      _c(
        "div",
        { key: _vm.eleCnt },
        [
          _c("separate-panel-components", {
            staticClass: "uml-separate-panel",
            attrs: {
              min: _vm.mainSeparatePanel.min,
              max: _vm.mainSeparatePanel.max,
              triggerLength: 5,
              paneLengthPercent: _vm.mainSeparatePanel.current,
              inBoundSeparatePanel: false,
            },
            on: {
              "update:paneLengthPercent": function ($event) {
                return _vm.$set(_vm.mainSeparatePanel, "current", $event)
              },
              "update:pane-length-percent": function ($event) {
                return _vm.$set(_vm.mainSeparatePanel, "current", $event)
              },
              close: function ($event) {
                return _vm.closeSeparatePanel()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "one",
                fn: function () {
                  return [
                    _c(
                      VSnackbar,
                      {
                        attrs: {
                          color: "primary",
                          "multi-line": "",
                          timeout: 5000,
                        },
                        model: {
                          value: _vm.alertSnackbar.show,
                          callback: function ($$v) {
                            _vm.$set(_vm.alertSnackbar, "show", $$v)
                          },
                          expression: "alertSnackbar.show",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.alertSnackbar.message) +
                            "\n                        "
                        ),
                        _c(
                          VBtn,
                          {
                            attrs: { dark: "" },
                            on: {
                              click: function ($event) {
                                _vm.alertSnackbar.show = false
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            Close\n                        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      VLayout,
                      { attrs: { right: "" } },
                      [
                        _vm.value
                          ? _c(
                              "opengraph",
                              {
                                key: _vm.openGraphRenderKey,
                                ref: "opengraph",
                                attrs: {
                                  "focus-canvas-on-select": "",
                                  wheelScalable: "",
                                  labelEditable: true,
                                  dragPageMovable: _vm.dragPageMovable,
                                  enableContextmenu: false,
                                  enableRootContextmenu: false,
                                  enableHotkeyCtrlC: false,
                                  enableHotkeyCtrlV: false,
                                  enableHotkeyDelete: false,
                                  enableHotkeyCtrlZ: false,
                                  enableHotkeyCtrlD: false,
                                  enableHotkeyCtrlG: false,
                                  slider: true,
                                  movable: true,
                                  resizable: true,
                                  selectable: true,
                                  connectable: true,
                                  autoSliderUpdate: true,
                                  imageBase: _vm.imageBase,
                                },
                                on: {
                                  canvasReady: _vm.bindEvents,
                                  connectShape: _vm.onConnectShape,
                                },
                              },
                              [
                                _vm._l(
                                  Object.keys(_vm.value.elements),
                                  function (elementId) {
                                    return _c(
                                      "div",
                                      { key: elementId },
                                      [
                                        elementId &&
                                        _vm.value.elements[elementId] != null
                                          ? _c(
                                              _vm.getComponentByClassName(
                                                _vm.value.elements[elementId]
                                                  ._type
                                              ),
                                              {
                                                ref: elementId,
                                                refInFor: true,
                                                tag: "component",
                                                attrs: {
                                                  value:
                                                    _vm.value.elements[
                                                      elementId
                                                    ],
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.value.elements,
                                                      elementId,
                                                      $event
                                                    )
                                                  },
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm._l(
                                  Object.keys(_vm.value.relations),
                                  function (relationId) {
                                    return _c(
                                      "div",
                                      { key: relationId },
                                      [
                                        relationId &&
                                        _vm.value.relations[relationId] != null
                                          ? _c(
                                              _vm.getComponentByClassName(
                                                _vm.value.relations[relationId]
                                                  ._type
                                              ),
                                              {
                                                ref: relationId,
                                                refInFor: true,
                                                tag: "component",
                                                attrs: {
                                                  value:
                                                    _vm.value.relations[
                                                      relationId
                                                    ],
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.value.relations,
                                                      relationId,
                                                      $event
                                                    )
                                                  },
                                                },
                                              }
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        !_vm.embedded
                          ? _c(
                              "div",
                              [
                                _c(
                                  VRow,
                                  { staticClass: "gs-modeling-undo-redo" },
                                  [
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    VBtn,
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "gs-model-z-index-2 gs-undo-opacity-hover",
                                                        attrs: {
                                                          disabled:
                                                            _vm.isUndoDisabled,
                                                          text: "",
                                                          small: "",
                                                          right: "",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.undo()
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: { medium: "" },
                                                        },
                                                        [_vm._v("mdi-undo")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          695928026
                                        ),
                                      },
                                      [_c("span", [_vm._v("Undo")])]
                                    ),
                                    _c(
                                      VTooltip,
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    VBtn,
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "gs-model-z-index-2 gs-undo-opacity-hover",
                                                        attrs: {
                                                          disabled:
                                                            _vm.isRedoDisabled,
                                                          text: "",
                                                          small: "",
                                                          right: "",
                                                        },
                                                        nativeOn: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.redo()
                                                          },
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: { medium: "" },
                                                        },
                                                        [_vm._v("mdi-redo")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1134616950
                                        ),
                                      },
                                      [_c("span", [_vm._v("Redo")])]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", {
                          staticClass: "d-flex justify-end",
                          staticStyle: {
                            position: "absolute",
                            top: "22px",
                            right: "70px",
                          },
                          style: _vm.embedded ? "" : "z-index: 1",
                        }),
                        !_vm.embedded
                          ? _c(
                              VFlex,
                              {
                                staticStyle: { justify: "end", align: "start" },
                              },
                              [
                                _c(
                                  VRow,
                                  {
                                    staticClass: "gs-model-z-index-1",
                                    staticStyle: {
                                      position: "absolute",
                                      left: "50%",
                                      transform: "translate(-50%, 0%)",
                                      "margin-top": "20px",
                                    },
                                  },
                                  [
                                    _c(VTextField, {
                                      staticStyle: {
                                        "margin-right": "5px",
                                        "max-width": "80px",
                                      },
                                      attrs: { label: "Project Name" },
                                      model: {
                                        value: _vm.projectName,
                                        callback: function ($$v) {
                                          _vm.projectName = $$v
                                        },
                                        expression: "projectName",
                                      },
                                    }),
                                    _c("text-reader", {
                                      staticStyle: { display: "inline-block" },
                                      attrs: {
                                        importType: "json",
                                        fileName: _vm.projectName,
                                      },
                                      on: {
                                        load: function ($event) {
                                          _vm.value = $event
                                        },
                                        "update:fileName": function ($event) {
                                          _vm.projectName = $event
                                        },
                                        "update:file-name": function ($event) {
                                          _vm.projectName = $event
                                        },
                                      },
                                    }),
                                    _c(
                                      VMenu,
                                      {
                                        staticStyle: { "margin-top": "-20px" },
                                        attrs: {
                                          "offset-y": "",
                                          "open-on-hover": "",
                                          left: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _vm.isReadOnlyModel
                                                    ? _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "uml-btn",
                                                              attrs: {
                                                                text: "",
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  _vm.disableBtn,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.saveComposition(
                                                                      "fork"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(VIcon, [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.icon
                                                                      .fork
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "uml-btn-text",
                                                                },
                                                                [_vm._v("FORK")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              staticClass:
                                                                "uml-btn",
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "5px",
                                                              },
                                                              attrs: {
                                                                color: _vm
                                                                  .joinRequestedText
                                                                  .show
                                                                  ? "primary"
                                                                  : "success",
                                                                disabled:
                                                                  _vm.disableBtn,
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.requestInviteUser()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm
                                                                .joinRequestedText
                                                                .show
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        VIcon,
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .icon
                                                                                .join
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "uml-btn-text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .joinRequestedText
                                                                        .text
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        [
                                                          _vm.isOwnModel ||
                                                          _vm.isClazzModeling
                                                            ? _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "uml-btn",
                                                                    attrs: {
                                                                      text: "",
                                                                      disabled:
                                                                        _vm.disableBtn,
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.saveComposition(
                                                                            "save"
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .save
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "uml-btn-text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "SAVE"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                VBtn,
                                                                {
                                                                  staticClass:
                                                                    "uml-btn",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    text: "",
                                                                    disabled:
                                                                      _vm.disableBtn,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.saveComposition(
                                                                          "fork"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .fork
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "uml-btn-text",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "FORK"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                          _c(
                                                            VBtn,
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openCommandViewer()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(VIcon, [
                                                                _vm._v(
                                                                  "mdi-code-greater-than"
                                                                ),
                                                              ]),
                                                              _c("div", [
                                                                _vm._v("code"),
                                                              ]),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3478235813
                                        ),
                                      },
                                      [
                                        _c(
                                          VList,
                                          _vm._l(
                                            _vm.saveItems,
                                            function (item, index) {
                                              return _c(
                                                VListItem,
                                                {
                                                  key: index,
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.functionSelect(
                                                        item.title,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(_vm._s(item.title)),
                                                  ]),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm.isOwnModel &&
                                    _vm.isServerModel &&
                                    !_vm.isReadOnlyModel
                                      ? _c(VMenu, {
                                          attrs: {
                                            "offset-y": "",
                                            "open-on-hover": "",
                                            left: "",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "uml-btn",
                                                          staticStyle: {
                                                            "margin-right":
                                                              "5px",
                                                          },
                                                          attrs: {
                                                            text: "",
                                                            disabled:
                                                              !_vm.initLoad,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openInviteUsers()
                                                            },
                                                          },
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.icon.share
                                                            )
                                                          ),
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "uml-btn-text",
                                                          },
                                                          [_vm._v("SHARE")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            1112714918
                                          ),
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.embedded && _vm.isReadOnlyModel
                          ? _c(
                              "div",
                              [
                                _c(
                                  VCard,
                                  {
                                    staticClass: "tools",
                                    staticStyle: {
                                      top: "100px",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "bpmn-icon-hand-tool",
                                      class: {
                                        icons: !_vm.dragPageMovable,
                                        hands: _vm.dragPageMovable,
                                      },
                                      attrs: { _width: "30", _height: "30" },
                                      on: { click: _vm.toggleGrip },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              VCard,
                              {
                                staticClass: "tools",
                                staticStyle: {
                                  top: "100px",
                                  "text-align": "center",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "bpmn-icon-hand-tool",
                                    class: {
                                      icons: !_vm.dragPageMovable,
                                      hands: _vm.dragPageMovable,
                                    },
                                    attrs: { _width: "30", _height: "30" },
                                    on: { click: _vm.toggleGrip },
                                  },
                                  [
                                    _c(
                                      VTooltip,
                                      { attrs: { "md-direction": "right" } },
                                      [_vm._v("Hands")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  _vm.elementTypes,
                                  function (category, categoryIndex) {
                                    return _c(
                                      VTooltip,
                                      {
                                        key: categoryIndex,
                                        attrs: { right: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "icons draggable",
                                                      attrs: {
                                                        align: "center",
                                                        _component:
                                                          category[0].component,
                                                        _width:
                                                          category[0].width,
                                                        _height:
                                                          category[0].height,
                                                        _description:
                                                          category[0]
                                                            .description,
                                                        _label:
                                                          category[0].label,
                                                      },
                                                      on: {
                                                        mouseover: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeCategory(
                                                            categoryIndex
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "img",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              height: "30px",
                                                              width: "30px",
                                                              src: category[0]
                                                                .src,
                                                            },
                                                          },
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(category[0].label)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                        _vm._l(
                          _vm.elementTypes,
                          function (category, categoryIndex) {
                            return _c("div", { key: categoryIndex }, [
                              _vm.selectedCategoryIndex == categoryIndex
                                ? _c(
                                    "div",
                                    _vm._l(category, function (item, key) {
                                      return _c(
                                        VTooltip,
                                        {
                                          key: key,
                                          attrs: { right: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              key > 0
                                                ? {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "draggable",
                                                            style:
                                                              _vm.toolStyle(
                                                                key,
                                                                categoryIndex,
                                                                category.length
                                                              ),
                                                            attrs: {
                                                              align: "center",
                                                              _component:
                                                                item.component,
                                                              _width:
                                                                item.width,
                                                              _height:
                                                                item.height,
                                                              _description:
                                                                item.description,
                                                              _label:
                                                                item.label,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                item.x =
                                                                  500 +
                                                                  Math.floor(
                                                                    Math.random() *
                                                                      200
                                                                  )
                                                                item.y =
                                                                  280 +
                                                                  Math.floor(
                                                                    Math.random() *
                                                                      150
                                                                  )
                                                                _vm.addElement(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "img",
                                                              _vm._g(
                                                                {
                                                                  staticStyle: {
                                                                    "vertical-align":
                                                                      "middle",
                                                                    border:
                                                                      "2 solid grey",
                                                                    "-webkit-box-shadow":
                                                                      "5px 5px 20px 0px rgba(0,0,0,0.75)",
                                                                    "-moz-box-shadow":
                                                                      "5px 5px 20px 0px rgba(0,0,0,0.40)",
                                                                    "box-shadow":
                                                                      "5px 5px 20px 0px rgba(0,0,0,0.40)",
                                                                  },
                                                                  attrs: {
                                                                    valign:
                                                                      "middle",
                                                                    onmouseover:
                                                                      "this.height=this.height*1.5;this.width=this.width*1.5;this.left=this.left-this.width*0.5;this.right=this.right-this.width*0.5;",
                                                                    onmouseout:
                                                                      "this.height=this.height/1.5;this.width=this.width/1.5;this.left=this.left+this.width*0.5;this.right=this.right+this.width*0.5;",
                                                                    height:
                                                                      "40px",
                                                                    width:
                                                                      "40px",
                                                                    src: item.src,
                                                                    border: "2",
                                                                  },
                                                                },
                                                                on
                                                              )
                                                            ),
                                                            _c(
                                                              VChip,
                                                              _vm._g({}, on),
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.label
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  }
                                                : null,
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            VCard,
                                            {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                "max-width": "400",
                                                "max-height": "400",
                                                outlined: "",
                                              },
                                            },
                                            [
                                              _c(
                                                VListItem,
                                                { attrs: { "three-line": "" } },
                                                [
                                                  _c(
                                                    VListItemContent,
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "overline mb-4",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              category[0].label
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        VListItemTitle,
                                                        {
                                                          staticClass:
                                                            "headline mb-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.label)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        VListItemSubtitle,
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.description
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VListItemAvatar,
                                                    {
                                                      attrs: {
                                                        tile: "",
                                                        size: "80",
                                                        color: "white",
                                                      },
                                                    },
                                                    [
                                                      _c(VImg, {
                                                        attrs: {
                                                          src: item.src,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ])
                          }
                        ),
                        _vm.showAutoModelingDialog
                          ? _c(
                              VCard,
                              {
                                staticStyle: {
                                  position: "absolute",
                                  top: "100px",
                                  right: "25px",
                                  width: "500px",
                                },
                              },
                              [
                                _c(
                                  VSystemBar,
                                  {
                                    staticStyle: { "justify-content": "right" },
                                  },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        attrs: { icon: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.closeAutoModelingDialog()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          VIcon,
                                          {
                                            staticStyle: {
                                              "margin-right": "-15px",
                                            },
                                            attrs: { small: "" },
                                          },
                                          [_vm._v("mdi-close")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  VCardText,
                                  {
                                    staticStyle: {
                                      "max-height": "70vh",
                                      "overflow-y": "scroll",
                                    },
                                    attrs: { id: "scroll_messageList" },
                                  },
                                  [
                                    _c(
                                      VCol,
                                      { attrs: { cols: "12" } },
                                      _vm._l(_vm.chatList, function (message) {
                                        return _c(
                                          "div",
                                          { key: message },
                                          [
                                            message.type == "prompt"
                                              ? _c(
                                                  VRow,
                                                  {
                                                    staticStyle: {
                                                      "justify-content":
                                                        "right",
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      VCard,
                                                      {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                          width: "350px",
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VCardText,
                                                          {
                                                            staticClass:
                                                              "auto-modeling-message",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  message.text
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : message.type == "response"
                                              ? _c(
                                                  VRow,
                                                  {
                                                    staticStyle: {
                                                      "margin-bottom": "20px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      VCard,
                                                      {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                          "background-color":
                                                            "#DAF5FF",
                                                          width: "400px",
                                                          "overflow-x":
                                                            "scroll",
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VCardText,
                                                          {
                                                            staticClass:
                                                              "auto-modeling-message",
                                                          },
                                                          [
                                                            _c(
                                                              "pre",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "small",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    message.text
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    ),
                                    _c("div"),
                                  ],
                                  1
                                ),
                                _c(
                                  VCard,
                                  {
                                    staticStyle: {
                                      "text-align": "-webkit-center",
                                      height: "65px",
                                    },
                                  },
                                  [
                                    _c(VTextField, {
                                      staticClass: "prompt_field",
                                      staticStyle: {
                                        width: "492px",
                                        "background-color": "#FFFFFF",
                                        color: "white",
                                      },
                                      attrs: {
                                        outlined: "",
                                        autofocus: "",
                                        "append-icon": "mdi-send",
                                      },
                                      on: {
                                        "click:append": function ($event) {
                                          return _vm.generate()
                                        },
                                        keypress: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.debouncedGenerate()
                                        },
                                      },
                                      model: {
                                        value: _vm.input.instruction,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.input,
                                            "instruction",
                                            $$v
                                          )
                                        },
                                        expression: "input.instruction",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c(
                      VDialog,
                      {
                        attrs: { "max-width": "500" },
                        model: {
                          value: _vm.classNameDialog,
                          callback: function ($$v) {
                            _vm.classNameDialog = $$v
                          },
                          expression: "classNameDialog",
                        },
                      },
                      [
                        _c(
                          VCard,
                          [
                            _c(VCardTitle, { staticClass: "headline" }, [
                              _vm._v("Class Name Definition"),
                            ]),
                            _c(
                              VCardText,
                              [
                                _c(VTextField, {
                                  attrs: { label: "Class Name", autofocus: "" },
                                  model: {
                                    value: _vm.newClassCompInfo.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.newClassCompInfo,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression: "newClassCompInfo.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              VCardActions,
                              [
                                _c(VSpacer),
                                _c(
                                  VBtn,
                                  {
                                    attrs: {
                                      color: "green darken-1",
                                      text: "",
                                    },
                                    on: { click: _vm.addClassElement },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Add\n                            "
                                    ),
                                  ]
                                ),
                                _c(
                                  VBtn,
                                  {
                                    attrs: { color: "red darken-1", text: "" },
                                    nativeOn: {
                                      click: function ($event) {
                                        _vm.classNameDialog = false
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Close\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("model-storage-dialog", {
                      attrs: {
                        condition: _vm.storageCondition,
                        showDialog: _vm.showStorageDialog,
                      },
                      on: {
                        save: _vm.saveModel,
                        fork: _vm.forkModel,
                        backup: _vm.backupModel,
                        close: _vm.storageDialogCancel,
                      },
                    }),
                    _c(
                      VDialog,
                      {
                        attrs: { "max-width": "290" },
                        model: {
                          value: _vm.forkAlertDialog,
                          callback: function ($$v) {
                            _vm.forkAlertDialog = $$v
                          },
                          expression: "forkAlertDialog",
                        },
                      },
                      [
                        _c(
                          VCard,
                          [
                            _c(
                              VCardTitle,
                              { staticClass: "headline" },
                              [
                                _vm._v("Fork\n                            "),
                                _c(VIcon, [_vm._v(_vm._s(_vm.icon.fork))]),
                              ],
                              1
                            ),
                            _c(VCardText, [
                              _vm._v(
                                " 권한이 없어서 수정 할 수 없습니다. Fork를 하여 사용해 주세요."
                              ),
                            ]),
                            _c(
                              VCardActions,
                              [
                                _c(VSpacer),
                                _c(
                                  VBtn,
                                  {
                                    attrs: {
                                      color: "green darken-1",
                                      text: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveComposition("fork")
                                      },
                                    },
                                  },
                                  [_vm._v("Fork")]
                                ),
                                _c(
                                  VBtn,
                                  {
                                    attrs: { color: "red darken-1", text: "" },
                                    nativeOn: {
                                      click: function ($event) {
                                        _vm.forkAlertDialog = false
                                      },
                                    },
                                  },
                                  [_vm._v("Close")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("model-canvas-share-dialog", {
                      attrs: {
                        showDialog: _vm.inviteDialog,
                        checkPublic: _vm.showPublicModel,
                        canvas: _vm.canvas,
                        canvasComponentName: "uml-class-model-canvas",
                      },
                      on: {
                        all: _vm.invitePublic,
                        apply: _vm.applyInviteUsers,
                        close: _vm.closeInviteUsers,
                        add: _vm.addInviteUser,
                        remove: _vm.removeInviteUser,
                      },
                      model: {
                        value: _vm.inviteLists,
                        callback: function ($$v) {
                          _vm.inviteLists = $$v
                        },
                        expression: "inviteLists",
                      },
                    }),
                    _c("modeler-image-generator", {
                      ref: "modeler-image-generator",
                    }),
                    _vm.projectId
                      ? _c("GeneratorUI", {
                          ref: "generatorUI",
                          attrs: {
                            projectId: _vm.projectId,
                            modelValue: _vm.value,
                            embedded: _vm.embedded,
                            defaultInputData: _vm.defaultGeneratorUiInputData,
                          },
                          on: {
                            createModel: _vm.createModel,
                            clearModelValue: _vm.clearModelValue,
                            modificateModel: _vm.modificateModel,
                          },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "two",
                fn: function () {
                  return [
                    _c("CodeGenerator", {
                      attrs: {
                        isOwnModel: _vm.isOwnModel,
                        isServerModel: _vm.isServerModel,
                        projectInformation: _vm.information,
                        projectName: _vm.projectName,
                        modelInitLoad: _vm.initLoad,
                        modelingProjectId: _vm.projectId,
                        asyncCodeForValue: false,
                        callCodeForValue: _vm.changedTemplateCode,
                        oldTreeHashLists: _vm.oldTreeHashLists,
                        newTreeHashLists: _vm.newTreeHashLists,
                        projectVersion: _vm.projectVersion,
                        "canvas-name": "uml-class-model-canvas",
                      },
                      on: {
                        "update:oldTreeHashLists": function ($event) {
                          _vm.oldTreeHashLists = $event
                        },
                        "update:old-tree-hash-lists": function ($event) {
                          _vm.oldTreeHashLists = $event
                        },
                        "update:newTreeHashLists": function ($event) {
                          _vm.newTreeHashLists = $event
                        },
                        "update:new-tree-hash-lists": function ($event) {
                          _vm.newTreeHashLists = $event
                        },
                        changedByMe: _vm.settingChangedByMe,
                      },
                      model: {
                        value: _vm.codeGenValue,
                        callback: function ($$v) {
                          _vm.codeGenValue = $$v
                        },
                        expression: "codeGenValue",
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._l(_vm.filteredMouseEventHandlers, function (otherMouseEvent, email) {
        return _c(
          "div",
          { key: email },
          [
            _c("MouseCursorComponent", {
              attrs: { mouseEvent: otherMouseEvent, email: email },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
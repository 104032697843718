import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        { attrs: { absolute: "", permanent: "", right: "", width: "500" } },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(VListItemAvatar, [
                    _c("img", { attrs: { src: _vm.img } }),
                  ]),
                  _c(
                    VListItemContent,
                    [
                      _c(VListItemTitle, { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.titleName)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.closePanel()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _c(VDivider),
              _c(
                VCard,
                { attrs: { outlined: "" } },
                [
                  _c(
                    VCardText,
                    [
                      _c(VTextField, {
                        attrs: {
                          label: "Name",
                          required: "",
                          autofocus: "",
                          disabled: _vm.isReadOnly,
                        },
                        model: {
                          value: _vm.value.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "name", $$v)
                          },
                          expression: "value.name",
                        },
                      }),
                      _c("span", { staticClass: "headline" }, [
                        _vm._v("Items"),
                      ]),
                      _c(VCheckbox, {
                        attrs: {
                          label: "Use Key & Value",
                          disabled: _vm.isReadOnly,
                        },
                        model: {
                          value: _vm.value.useKeyValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "useKeyValue", $$v)
                          },
                          expression: "value.useKeyValue",
                        },
                      }),
                      _c(
                        VLayout,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCol,
                            [
                              _c(
                                "draggable",
                                _vm._b(
                                  {
                                    on: {
                                      start: function ($event) {
                                        _vm.drag = true
                                      },
                                      end: function ($event) {
                                        _vm.drag = false
                                      },
                                    },
                                    model: {
                                      value: _vm.value.items,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.value, "items", $$v)
                                      },
                                      expression: "value.items",
                                    },
                                  },
                                  "draggable",
                                  _vm.dragOptions,
                                  false
                                ),
                                [
                                  _c(
                                    "transition-group",
                                    {
                                      attrs: {
                                        type: "transition",
                                        name: !_vm.drag ? "flip-list" : null,
                                      },
                                    },
                                    _vm._l(
                                      _vm.value.items,
                                      function (item, idx) {
                                        return _c(
                                          "div",
                                          { key: "item" + idx },
                                          [
                                            _c(
                                              VRow,
                                              {
                                                staticClass: "mb-6",
                                                attrs: { "no-gutters": "" },
                                              },
                                              [
                                                _vm.value.useKeyValue
                                                  ? _c(
                                                      VCol,
                                                      { attrs: { cols: "5" } },
                                                      [
                                                        !_vm.itemEdit ||
                                                        _vm.value.items.indexOf(
                                                          item
                                                        ) != _vm.itemEditIndex
                                                          ? _c("div", [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    item.key
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ])
                                                          : _vm.itemEdit &&
                                                            _vm.value.items.indexOf(
                                                              item
                                                            ) ==
                                                              _vm.itemEditIndex
                                                          ? _c(VTextField, {
                                                              staticClass:
                                                                "mr-2 mt-0 pt-0",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isReadOnly,
                                                              },
                                                              model: {
                                                                value: item.key,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "key",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.key",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  VCol,
                                                  {
                                                    attrs: {
                                                      cols: _vm.value
                                                        .useKeyValue
                                                        ? "5"
                                                        : "10",
                                                    },
                                                  },
                                                  [
                                                    !_vm.itemEdit ||
                                                    _vm.value.items.indexOf(
                                                      item
                                                    ) != _vm.itemEditIndex
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                item.value
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ])
                                                      : _vm.itemEdit &&
                                                        _vm.value.items.indexOf(
                                                          item
                                                        ) == _vm.itemEditIndex
                                                      ? _c(VTextField, {
                                                          staticClass:
                                                            "mr-2 mt-0 pt-0",
                                                          attrs: {
                                                            disabled:
                                                              _vm.isReadOnly,
                                                          },
                                                          model: {
                                                            value: item.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.value",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  VCol,
                                                  { attrs: { cols: "2" } },
                                                  [
                                                    _vm.itemEdit &&
                                                    _vm.value.items.indexOf(
                                                      item
                                                    ) == _vm.itemEditIndex
                                                      ? _c(
                                                          VBtn,
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              "x-small": "",
                                                              icon: "",
                                                              disabled:
                                                                _vm.isReadOnly,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.modifyItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(VIcon, [
                                                              _vm._v("save"),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : !_vm.itemEdit ||
                                                        _vm.value.items.indexOf(
                                                          item
                                                        ) != _vm.itemEditIndex
                                                      ? _c(
                                                          VBtn,
                                                          {
                                                            staticClass: "mr-2",
                                                            attrs: {
                                                              "x-small": "",
                                                              icon: "",
                                                              disabled:
                                                                _vm.isReadOnly,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editItem(
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(VIcon, [
                                                              _vm._v("edit"),
                                                            ]),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      VBtn,
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          icon: "",
                                                          disabled:
                                                            _vm.isReadOnly,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteItem(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(VIcon, [
                                                          _vm._v("delete"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                { attrs: { justify: "center" } },
                                [
                                  _vm.value.useKeyValue
                                    ? _c(VTextField, {
                                        attrs: {
                                          label: "Key",
                                          disabled: _vm.isReadOnly,
                                        },
                                        model: {
                                          value: _vm.itemKey,
                                          callback: function ($$v) {
                                            _vm.itemKey = $$v
                                          },
                                          expression: "itemKey",
                                        },
                                      })
                                    : _vm._e(),
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Value",
                                      required: "",
                                      disabled: _vm.isReadOnly,
                                    },
                                    on: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.addItem.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.itemValue,
                                      callback: function ($$v) {
                                        _vm.itemValue = $$v
                                      },
                                      expression: "itemValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                { attrs: { justify: "end" } },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticStyle: { color: "#1E88E5" },
                                      attrs: {
                                        depressed: "",
                                        text: "",
                                        disabled: _vm.isReadOnly,
                                      },
                                      on: { click: _vm.addItem },
                                    },
                                    [_vm._v("Add Item")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "group-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            resizable: _vm.resizable,
            deletable: _vm.deletable,
            connectable: _vm.connectable,
            id: _vm.value.elementView.id,
            x: _vm.value.elementView.x,
            y: _vm.value.elementView.y,
            width: _vm.value.elementView.width,
            height: _vm.value.elementView.height,
            angle: _vm.value.elementView.angle,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            _style: {
              "label-angle": _vm.value.elementView.angle,
              "font-weight": "bold",
              "font-size": "14",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.value.elementView, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.value.elementView, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.value.elementView, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.value.elementView, "height", $event)
            },
            "update:angle": function ($event) {
              return _vm.$set(_vm.value.elementView, "angle", $event)
            },
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            removeShape: _vm.onRemoveShape,
            dblclick: _vm.openPanel,
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            addedToGroup: _vm.onAddedToGroup,
          },
        },
        [
          _c("geometry-rect", {
            attrs: {
              _style: {
                "fill-r": 1,
                "fill-cx": 0.1,
                "fill-cy": 0.1,
                "stroke-width": 1.4,
                stroke: "#ffffff",
                "fill-opacity": 1,
                r: "1",
              },
            },
          }),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.value.elementView.height,
                },
              }),
            ],
            1
          ),
          _c(
            "sub-elements",
            [
              _c("rectangle-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": _vm.titleH,
                  "sub-top": 0,
                  subStyle: {
                    stroke: "#FFA400",
                    fill: "#FFA400",
                    "fill-opacity": 1,
                  },
                },
              }),
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": _vm.titleH / 2,
                  "sub-top": 0,
                  "sub-left": 0,
                  text: "<< Exception >>",
                },
              }),
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": _vm.titleH,
                  "sub-top": 10,
                  "sub-left": 0,
                  text: _vm.name,
                  subStyle: {
                    "font-size": "14",
                    "font-weight": "bold",
                  },
                },
                on: {
                  "update:text": function ($event) {
                    _vm.name = $event
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "sub-elements",
            [
              _c("rectangle-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": _vm.value.elementView.height - _vm.titleH,
                  "sub-top": _vm.titleH,
                  subStyle: {
                    stroke: "#050038",
                    fill: "#050038",
                    "fill-opacity": 1,
                  },
                },
              }),
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": _vm.value.elementView.height - _vm.titleH,
                  "sub-top": _vm.titleH,
                  "sub-left": 10,
                  text: _vm.value.message,
                  subStyle: {
                    "text-anchor": "start",
                    "font-size": "14",
                    "font-color": "#FAFAFA",
                  },
                },
                on: {
                  "update:text": function ($event) {
                    return _vm.$set(_vm.value, "message", $event)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("uml-exception-panel", {
            attrs: {
              titleName: "Exception Class",
              img: _vm.imgSrc,
              isReadOnly: !_vm.isEditElement,
            },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("UMLClassModelCanvas", {
    key: _vm.componentKey,
    ref: "designer",
    attrs: {
      elementListBeanPath: "classDefinitions",
      relationListBeanPath: "relations",
      relationVueComponentName: "uml-class-relation",
    },
    on: {
      forceUpdateKey: function ($event) {
        return _vm.forceRerender()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "sub-controller",
        {
          attrs: { image: "association_arrow.png", cloneable: "" },
          on: { clone: _vm.associationEdgeAndElement },
        },
        [_c("circle-element", { attrs: { width: 100, height: 100 } })],
        1
      ),
      _c(
        "sub-controller",
        {
          attrs: { image: "aggregation_arrow.png", cloneable: "" },
          on: { clone: _vm.aggregationEdgeAndElement },
        },
        [_c("circle-element", { attrs: { width: 100, height: 100 } })],
        1
      ),
      _c(
        "sub-controller",
        {
          attrs: { image: "generalization_arrow.png", cloneable: "" },
          on: { clone: _vm.generalizationEdgeAndElement },
        },
        [_c("circle-element", { attrs: { width: 100, height: 100 } })],
        1
      ),
      _vm.value._type.endsWith("Class")
        ? _c("sub-controller", {
            attrs: { image: "tag.png" },
            on: {
              click: function ($event) {
                return _vm.addObject("attribute")
              },
            },
          })
        : _vm._e(),
      _vm.value._type.endsWith("Class")
        ? _c("sub-controller", {
            attrs: { image: "repair.png" },
            on: {
              click: function ($event) {
                return _vm.addObject("operation")
              },
            },
          })
        : _vm._e(),
      _vm.value.isAggregateRoot
        ? _c("sub-controller", {
            attrs: { image: "chatgpt.png" },
            on: { click: _vm.openAutoModeling },
          })
        : _vm._e(),
      _c(
        VDialog,
        {
          attrs: { width: "500" },
          model: {
            value: _vm.editDialog,
            callback: function ($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog",
          },
        },
        [
          _c("uml-class-popup", {
            attrs: {
              type: _vm.addType,
              isReadOnly: !_vm.isEditElement,
              isNew: true,
            },
            on: { close: _vm.closePopup },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VTooltip,
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c("span", _vm._g({ staticClass: "headline" }, on), [
                    _vm._v(_vm._s(_vm.label)),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _vm.type == "org.uengine.modeling.model.Aggregate"
            ? _c("span", [
                _vm._v(
                  "Allowing both primitive types and non-primitive types including user defined Entities or Value Objects."
                ),
              ])
            : _c("span", [_vm._v("Only primitive types are allowed.")]),
        ]
      ),
      _c(
        VLayout,
        {
          attrs: { flat: "" },
          on: {
            contextmenu: function ($event) {
              $event.preventDefault()
              return _vm.handleClick($event, _vm.element)
            },
          },
        },
        [
          _c(
            VCol,
            [
              _c(
                "draggable",
                _vm._b(
                  {
                    on: {
                      start: function ($event) {
                        _vm.drag = true
                      },
                      end: function ($event) {
                        _vm.drag = false
                      },
                    },
                    model: {
                      value: _vm.dataValue,
                      callback: function ($$v) {
                        _vm.dataValue = $$v
                      },
                      expression: "dataValue",
                    },
                  },
                  "draggable",
                  _vm.dragOptions,
                  false
                ),
                [
                  _c(
                    "transition-group",
                    {
                      attrs: {
                        type: "transition",
                        name: !_vm.drag ? "flip-list" : null,
                      },
                    },
                    _vm._l(_vm.value, function (element, idx) {
                      return _c(
                        "div",
                        { key: idx },
                        [
                          _c(
                            VRow,
                            {
                              class: {
                                "mb-6":
                                  !_vm.attributeEdit ||
                                  _vm.value.indexOf(element) !=
                                    _vm.attributeEditIndex,
                              },
                              attrs: { "no-gutters": "" },
                            },
                            [
                              _c(
                                VCol,
                                {
                                  staticStyle: { height: "30px" },
                                  attrs: { cols: "1" },
                                },
                                [
                                  _c(VCheckbox, {
                                    staticClass: "mt-0 mb-0 pt-0",
                                    attrs: { disabled: _vm.isReadOnly },
                                    on: {
                                      change: function ($event) {
                                        return _vm.addCopyList($event, element)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                {
                                  staticStyle: { "margin-right": "4px" },
                                  attrs: { cols: "1" },
                                },
                                [
                                  element.isKey
                                    ? _c(
                                        VIcon,
                                        { attrs: { small: "", disabled: "" } },
                                        [
                                          _vm._v(
                                            "\n                                    mdi-key\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  element.isCorrelationKey
                                    ? _c(
                                        VIcon,
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            left: "9px",
                                            "margin-top": "5px",
                                          },
                                          attrs: { small: "", disabled: "" },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    mdi-link-variant\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                { attrs: { cols: "3" } },
                                [
                                  !_vm.attributeEdit ||
                                  _vm.value.indexOf(element) !=
                                    _vm.attributeEditIndex
                                    ? _c(
                                        "div",
                                        {
                                          style: _vm.isDuplicated(element)
                                            ? "color:red"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(element.className) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm.attributeEdit &&
                                      _vm.value.indexOf(element) ==
                                        _vm.attributeEditIndex
                                    ? _c(VSelect, {
                                        staticClass:
                                          "mr-2 mt-0 pt-0 cp-select-box",
                                        attrs: { items: _vm.entityTypeList },
                                        model: {
                                          value: element.className,
                                          callback: function ($$v) {
                                            _vm.$set(element, "className", $$v)
                                          },
                                          expression: "element.className",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                { attrs: { cols: "4" } },
                                [
                                  !_vm.attributeEdit ||
                                  _vm.value.indexOf(element) !=
                                    _vm.attributeEditIndex
                                    ? _c(
                                        "div",
                                        {
                                          style: _vm.isDuplicated(element)
                                            ? "color:red"
                                            : "",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(element.name) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm.attributeEdit &&
                                      _vm.value.indexOf(element) ==
                                        _vm.attributeEditIndex
                                    ? _c(VTextField, {
                                        staticClass: "mr-2 mt-0 pt-0",
                                        attrs: { required: "" },
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.modifyAttributeItem(
                                              element
                                            )
                                          },
                                        },
                                        model: {
                                          value: element.name,
                                          callback: function ($$v) {
                                            _vm.$set(element, "name", $$v)
                                          },
                                          expression: "element.name",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                VCol,
                                { attrs: { cols: "2" } },
                                [
                                  _vm.attributeEdit &&
                                  _vm.value.indexOf(element) ==
                                    _vm.attributeEditIndex
                                    ? _c(
                                        VIcon,
                                        {
                                          staticClass: "mr-2 cp-save-button",
                                          attrs: {
                                            small: "",
                                            disabled: _vm.isReadOnly,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.modifyAttributeItem(
                                                element
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    mdi-content-save\n                                "
                                          ),
                                        ]
                                      )
                                    : !_vm.attributeEdit ||
                                      _vm.value.indexOf(element) !=
                                        _vm.attributeEditIndex
                                    ? _c(
                                        VIcon,
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            small: "",
                                            disabled: _vm.isReadOnly,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.editAttributeItem(
                                                element
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    edit\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    VIcon,
                                    {
                                      attrs: {
                                        small: "",
                                        disabled: _vm.isReadOnly,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteAttribute(
                                            element,
                                            idx
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    delete\n                                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.attributeEdit &&
                          _vm.value.indexOf(element) == _vm.attributeEditIndex
                            ? _c(
                                VCol,
                                {
                                  staticStyle: {
                                    "margin-top": "-30px",
                                    "margin-left": "30px",
                                  },
                                },
                                [
                                  _c(
                                    VRow,
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _c(VCheckbox, {
                                        staticStyle: {
                                          "font-size": "small",
                                          "margin-right": "15px",
                                        },
                                        attrs: { label: "Key" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectKey(element)
                                          },
                                        },
                                        model: {
                                          value: element.isKey,
                                          callback: function ($$v) {
                                            _vm.$set(element, "isKey", $$v)
                                          },
                                          expression: "element.isKey",
                                        },
                                      }),
                                      _c(VCheckbox, {
                                        staticStyle: {
                                          "font-size": "small",
                                          "margin-right": "15px",
                                        },
                                        attrs: { label: "Correlation Key" },
                                        model: {
                                          value: element.isCorrelationKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              element,
                                              "isCorrelationKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "element.isCorrelationKey",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticStyle: { width: "100px" } },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              label: "Display Name",
                                              disabled: _vm.isReadOnly,
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.modifyAttributeItem(
                                                  element
                                                )
                                              },
                                            },
                                            model: {
                                              value: element.displayName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  element,
                                                  "displayName",
                                                  $$v
                                                )
                                              },
                                              expression: "element.displayName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VRow,
                                    { staticStyle: { "margin-top": "-30px" } },
                                    [
                                      _c(VCheckbox, {
                                        staticStyle: {
                                          "font-size": "small",
                                          "margin-right": "15px",
                                        },
                                        attrs: { label: "List" },
                                        model: {
                                          value: element.isList,
                                          callback: function ($$v) {
                                            _vm.$set(element, "isList", $$v)
                                          },
                                          expression: "element.isList",
                                        },
                                      }),
                                      _c(VCheckbox, {
                                        staticStyle: {
                                          "font-size": "small",
                                          "margin-right": "15px",
                                        },
                                        attrs: { label: "Name" },
                                        model: {
                                          value: element.isName,
                                          callback: function ($$v) {
                                            _vm.$set(element, "isName", $$v)
                                          },
                                          expression: "element.isName",
                                        },
                                      }),
                                      _c(VCheckbox, {
                                        staticStyle: { "font-size": "small" },
                                        attrs: { label: "Large Object" },
                                        model: {
                                          value: element.isLob,
                                          callback: function ($$v) {
                                            _vm.$set(element, "isLob", $$v)
                                          },
                                          expression: "element.isLob",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                VRow,
                {
                  staticClass: "attribute-editor",
                  attrs: { justify: "center" },
                },
                [
                  _c(VSelect, {
                    staticClass: "attribute-type",
                    staticStyle: { width: "30px" },
                    attrs: {
                      items: _vm.entityTypeList,
                      disabled: _vm.isReadOnly,
                      label: "Type",
                    },
                    model: {
                      value: _vm.entityType,
                      callback: function ($$v) {
                        _vm.entityType = $$v
                      },
                      expression: "entityType",
                    },
                  }),
                  _c(VTextField, {
                    staticClass: "attribute-name",
                    attrs: {
                      label: "Name",
                      disabled: _vm.isReadOnly,
                      required: "",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.addAttribute(_vm.entityType, _vm.entityName)
                      },
                    },
                    model: {
                      value: _vm.entityName,
                      callback: function ($$v) {
                        _vm.entityName = $$v
                      },
                      expression: "entityName",
                    },
                  }),
                ],
                1
              ),
              _c(
                VRow,
                { attrs: { justify: "end" } },
                [
                  _vm.type == "org.uengine.modeling.model.Event" ||
                  _vm.type == "org.uengine.modeling.model.Command" ||
                  (_vm.type == "org.uengine.modeling.model.View" &&
                    _vm.dataProjection == "query-for-aggregate")
                    ? _c(
                        VBtn,
                        {
                          staticStyle: {
                            "margin-right": "10px",
                            color: "#1E88E5",
                          },
                          attrs: {
                            depressed: "",
                            text: "",
                            disabled: _vm.isReadOnly,
                          },
                          on: { click: _vm.syncAttribute },
                        },
                        [
                          _c(
                            VIcon,
                            { attrs: { color: "black", small: "" } },
                            [_vm._v("mdi-refresh")]
                          ),
                          _vm._v(
                            "\n                    Sync attributes\n                "
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        "margin-bottom": "10px",
                        color: "#1E88E5",
                      },
                      attrs: {
                        depressed: "",
                        text: "",
                        disabled: _vm.isReadOnly,
                        dark: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addAttribute(
                            _vm.entityType,
                            _vm.entityName
                          )
                        },
                      },
                    },
                    [_vm._v("ADD ATTRIBUTE")]
                  ),
                ],
                1
              ),
              _vm.type == "org.uengine.modeling.model.Aggregate"
                ? _c(
                    VRow,
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        VTooltip,
                        {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticStyle: { color: "#1E88E5" },
                                          attrs: {
                                            disabled: _vm.isReadOnly,
                                            depressed: "",
                                            text: "",
                                          },
                                          on: { click: _vm.openUmlClass },
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                            Edit Aggregate Members by Class Diagram\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4095245831
                          ),
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "This menu interacts with the Domain Class Modeling Tool to define their ubiquitous language with object-oriented manner."
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.enumDialog,
            callback: function ($$v) {
              _vm.enumDialog = $$v
            },
            expression: "enumDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, [_vm._v("New Enumeration")]),
              _c(
                VCardText,
                [
                  _c(VTextField, {
                    attrs: { label: "name" },
                    model: {
                      value: _vm.enumValue.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.enumValue, "name", $$v)
                      },
                      expression: "enumValue.name",
                    },
                  }),
                  _vm.enumValue.items.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mb-3" },
                        _vm._l(_vm.enumValue.items, function (item, idx) {
                          return _c(
                            VRow,
                            { key: idx },
                            [
                              _c(VCol, { attrs: { cols: "11" } }, [
                                _vm._v(_vm._s(item.value)),
                              ]),
                              _c(
                                VCol,
                                { attrs: { cols: "1" } },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { "x-small": "", icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.enumValue.items.splice(
                                            idx,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_c(VIcon, [_vm._v("delete")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    : _vm._e(),
                  _c(VTextField, {
                    attrs: { label: "Value" },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.addEnumValue.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.itemValue.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.itemValue, "value", $$v)
                      },
                      expression: "itemValue.value",
                    },
                  }),
                  _c(
                    VRow,
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.addEnumValue },
                        },
                        [_vm._v("Add Item")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.enumDialog = false
                        },
                      },
                    },
                    [_vm._v("Close")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: { click: _vm.addEnumeration },
                    },
                    [_vm._v("Save")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("vue-context-menu", {
        ref: "vueSimpleContextMenu",
        attrs: { elementId: _vm.elementId, options: _vm.menuList },
        on: { "option-clicked": _vm.optionClicked },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
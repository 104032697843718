var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("edge-element", {
        attrs: {
          selectable: _vm.selectable,
          movable: _vm.movable,
          deletable: _vm.deletable,
          connectable: _vm.connectable,
          id: _vm.value.relationView.id,
          vertices: _vm.vertices,
          customMoveActionExist: _vm.canvas.isCustomMoveExist,
          _style: {
            "arrow-start": "none",
            "arrow-end": "none",
            "stroke-width": _vm.value.size,
            stroke: _vm.value.color,
            "stroke-dasharray": _vm.value.dashStyle,
          },
        },
        on: {
          "update:id": function ($event) {
            return _vm.$set(_vm.value.relationView, "id", $event)
          },
          "update:vertices": function ($event) {
            _vm.vertices = $event
          },
          selectShape: _vm.selectedActivity,
          deSelectShape: _vm.deSelectedActivity,
          dblclick: _vm.openPanel,
          customRelationMoveAction: _vm.delayedRelationMove,
          moveShape: _vm.onMoveShape,
          removeShape: _vm.onRemoveShape,
        },
      }),
      _vm.propertyPanel
        ? _c("uml-text-line-panel", {
            attrs: {
              titleName: "Line",
              img: _vm.imgSrc,
              isReadOnly: !_vm.isEditElement,
            },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
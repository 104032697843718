var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "group-element",
        {
          attrs: {
            selectable: "",
            movable: "",
            resizable: "",
            deletable: "",
            id: _vm.value.elementView.id,
            x: _vm.value.elementView.x,
            y: _vm.value.elementView.y,
            width: _vm.value.elementView.width,
            height: _vm.value.elementView.height,
            angle: _vm.value.elementView.angle,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            label: _vm.name,
            _style: {
              "label-angle": _vm.value.elementView.angle,
              "font-weight": "bold",
              "font-size": "14",
              "vertical-align": "top",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.value.elementView, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.value.elementView, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.value.elementView, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.value.elementView, "height", $event)
            },
            "update:angle": function ($event) {
              return _vm.$set(_vm.value.elementView, "angle", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            removeShape: _vm.onRemoveShape,
            addToGroup: _vm.onAddToGroup,
            "update:label": function ($event) {
              _vm.name = $event
            },
          },
        },
        [
          _c("geometry-rect", {
            attrs: {
              _style: {
                "fill-r": 1,
                "fill-cx": 0.1,
                "fill-cy": 0.1,
                "stroke-width": 1.4,
                stroke: "#000000",
                "stroke-dasharray": "- ",
                "fill-opacity": 1,
                r: "1",
              },
            },
          }),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.value.elementView.height,
                },
              }),
            ],
            1
          ),
          _vm.isEditElement
            ? _c("uml-sub-controller", { attrs: { value: _vm.value } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-element", {
        attrs: {
          movable: !_vm.isReadOnly,
          selectable: _vm.isMovable,
          deletable: _vm.isDeletable,
          _style: {
            "text-anchor": "start",
            "font-size": "14",
            "font-color": _vm.fontColor,
          },
          x: _vm.x,
          y: _vm.y,
          width: _vm.width,
          text: _vm.label,
        },
        on: {
          moveShape: _vm.onMoveShape,
          addedToGroup: _vm.onAddedToClass,
          removeShape: _vm.onRemoveShape,
          dblclick: _vm.openEditDialog,
          "update:x": function ($event) {
            _vm.x = $event
          },
          "update:y": function ($event) {
            _vm.y = $event
          },
          "update:width": function ($event) {
            _vm.width = $event
          },
          "update:text": function ($event) {
            _vm.label = $event
          },
        },
      }),
      _c(
        VDialog,
        {
          attrs: { width: "500" },
          model: {
            value: _vm.editDialog,
            callback: function ($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog",
          },
        },
        [
          _c("uml-class-popup", {
            attrs: {
              type: _vm.type,
              isReadOnly: !_vm.isEditElement,
              isNew: false,
              index: _vm.styles.index,
            },
            on: { close: _vm.closePopup },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
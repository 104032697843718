var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("edge-element", {
        attrs: {
          selectable: _vm.selectable,
          movable: _vm.movable,
          deletable: _vm.deletable,
          connectable: _vm.connectable,
          id: _vm.value.relationView.id,
          vertices: _vm.vertices,
          from: _vm.value.from,
          to: _vm.value.to,
          _style: _vm.style_,
          label: _vm.name,
          fromLabel: _vm.value.fromLabel ? _vm.value.fromLabel : "",
          toLabel: _vm.value.toLabel ? _vm.value.toLabel : "",
          customMoveActionExist: _vm.canvas.isCustomMoveExist,
        },
        on: {
          "update:vertices": function ($event) {
            _vm.vertices = $event
          },
          "update:from": function ($event) {
            return _vm.$set(_vm.value, "from", $event)
          },
          "update:to": function ($event) {
            return _vm.$set(_vm.value, "to", $event)
          },
          selectShape: _vm.selectedActivity,
          deSelectShape: _vm.deSelectedActivity,
          removeShape: _vm.onRemoveShape,
          dblclick: _vm.openPanel,
          customRelationMoveAction: _vm.delayedRelationMove,
        },
      }),
      _vm.propertyPanel
        ? _c("uml-relation-panel", {
            attrs: {
              titleName: "UML Class Relation",
              img: _vm.imgSrc,
              isReadOnly: !_vm.isEditElement,
            },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
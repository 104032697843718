var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "geometry-element",
        {
          attrs: {
            selectable: _vm.selectable,
            movable: _vm.movable,
            resizable: _vm.resizable,
            deletable: _vm.deletable,
            id: _vm.value.elementView.id,
            x: _vm.value.elementView.x,
            y: _vm.value.elementView.y,
            width: _vm.value.elementView.width,
            height: _vm.value.elementView.height,
            _style: {
              "label-angle": _vm.value.elementView.angle,
              "text-anchor": "middle",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.value.elementView, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.value.elementView, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.value.elementView, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.value.elementView, "height", $event)
            },
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            removeShape: _vm.onRemoveShape,
            dblclick: _vm.openPanel,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
          },
        },
        [
          !_vm.movingElement
            ? _c("geometry-rect", {
                attrs: {
                  _style: {
                    "fill-r": 1,
                    "fill-cx": 0.1,
                    "fill-cy": 0.1,
                    "fill-opacity": 0,
                    "stroke-width": 0,
                    r: "1",
                  },
                },
              })
            : _vm._e(),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.value.elementView.height,
                },
              }),
            ],
            1
          ),
          _c(
            "sub-elements",
            [
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-top": 0,
                  "sub-left": 0,
                  text: _vm.name,
                  subStyle: {
                    "font-size": _vm.fontSize,
                    "font-weight": "bold",
                  },
                },
                on: {
                  "update:text": function ($event) {
                    _vm.name = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("uml-text-line-panel", {
            attrs: {
              titleName: "Line",
              img: _vm.imgSrc,
              isReadOnly: !_vm.isEditElement,
            },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
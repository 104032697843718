import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        { attrs: { absolute: "", permanent: "", right: "", width: "500" } },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(VListItemAvatar, [
                    _c("img", { attrs: { src: _vm.img } }),
                  ]),
                  _c(
                    VListItemContent,
                    [
                      _c(VListItemTitle, { staticClass: "headline" }, [
                        _vm._v(_vm._s(_vm.value.relationType)),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.closePanel()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _c(VDivider),
              _c(
                VCard,
                { attrs: { outlined: "" } },
                [
                  _c(
                    VCardText,
                    [
                      _c(VTextField, {
                        attrs: {
                          label: "Relation Name",
                          required: "",
                          autofocus: "",
                          disabled: _vm.isReadOnly,
                        },
                        model: {
                          value: _vm.value.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "name", $$v)
                          },
                          expression: "value.name",
                        },
                      }),
                      !_vm.value.relationType.includes("Realization")
                        ? _c(VSelect, {
                            attrs: {
                              items: _vm.relationTypes,
                              "item-text": "name",
                              "item-value": "type",
                              label: "Relation Type",
                              disabled: _vm.isReadOnly,
                            },
                            model: {
                              value: _vm.value.relationType,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "relationType", $$v)
                              },
                              expression: "value.relationType",
                            },
                          })
                        : _vm._e(),
                      _vm.value.relationType.includes("Aggregation") ||
                      _vm.value.relationType.includes("Composition") ||
                      _vm.value.relationType.includes("Association")
                        ? _c(
                            "div",
                            [
                              _c(VSelect, {
                                attrs: {
                                  items: _vm.multiplicityList,
                                  label: "Source Multiplicity",
                                  disabled: _vm.isReadOnly,
                                },
                                model: {
                                  value: _vm.value.sourceMultiplicity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value,
                                      "sourceMultiplicity",
                                      $$v
                                    )
                                  },
                                  expression: "value.sourceMultiplicity",
                                },
                              }),
                              _c(VSelect, {
                                attrs: {
                                  items: _vm.multiplicityList,
                                  label: "Target Multiplicity",
                                  disabled: _vm.isReadOnly,
                                },
                                model: {
                                  value: _vm.value.targetMultiplicity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value,
                                      "targetMultiplicity",
                                      $$v
                                    )
                                  },
                                  expression: "value.targetMultiplicity",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(VTextField, {
                        attrs: {
                          label: "Source Role",
                          disabled: _vm.isReadOnly,
                        },
                        model: {
                          value: _vm.value.fromLabel,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "fromLabel", $$v)
                          },
                          expression: "value.fromLabel",
                        },
                      }),
                      _c(VTextField, {
                        attrs: {
                          label: "Target Role",
                          disabled: _vm.isReadOnly,
                        },
                        model: {
                          value: _vm.value.toLabel,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "toLabel", $$v)
                          },
                          expression: "value.toLabel",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("uml-class-model-canvas", {
    ref: "designer",
    attrs: {
      elementListBeanPath: "classDefinitions",
      relationListBeanPath: "relations",
      relationVueComponentName: "uml-class-relation",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }